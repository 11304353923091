<template>
  <app-card>
    <template #title>{{ $t('label.water_pressure') }}</template>
    <template #body>
      <app-table :request-url="`/api/${blockId}/waterPressure/table`" :fields="fields" :filters="{block: blockId}" :update-data="updateDataFlag">
        <template #cell(id)="data">
          <router-link :to="`/water-pressure/${data.item.id}/update`" class="text-primary text-hover-success">
            {{ data.value }}
          </router-link>
        </template>
        <template #cell(zones)="data">{{ Object.values(data.value).map(el => el.name).join(', ') }}</template>
        <template #cell(type)="data">{{ data.value.name }}</template>
        <template #cell(created_by)="data">{{ data.value.full_name }}</template>
      </app-table>
    </template>
  </app-card>
</template>

<script>
import AppCard from '@/components/AppCard';
import AppTable from '@/components/lists/AppTable';

export default {
  components: {
    AppCard,
    AppTable
  },
  props: {
    blockId: {
      required: true
    },
    updateDataFlag: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fields: [
        {key: 'id', label: 'ID'},
        {key: 'date', label: this.$t('label.date')},
        {key: 'time', label: this.$t('label.time')},
        {key: 'zones', label: this.$t('label.zones')},
        {key: 'city', label: this.$t('label.city_pressure')},
        {key: 'system_integrity', label: this.$t('label.system_integrity')},
        {key: 'damage_type', label: this.$t('label.damage_type')},
        {key: 'created_by', label: this.$t('label.created_by')},
        {key: 'created_at', label: this.$t('label.created_at')},
        {key: 'updated_at', label: this.$t('label.updated_at')}
      ]
    }
  },
}
</script>