<template>
  <div>
    <div v-if="block" class="d-flex flex-row" id="kt_wrapper">
      <!--begin::Aside-->
      <div
        class="flex-row-auto offcanvas-mobile w-250px w-xxl-300px"
        :class="{'offcanvas-mobile-on': subheaderMobileState}"
        id="kt_profile_aside"
        v-click-outside="handleFocusOut"
      >
        <!--begin::Profile Card-->
        <app-card>
          <template #body>
            <div class="d-flex align-items-center">
              <div class="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center">
                <span class="symbol-label font-size-h1">{{ block.name }}</span>
              </div>
              <div>
                <span class="font-weight-bolder font-size-h3 text-dark-75">
                  {{ `${$t('label.block')} ${block.name}` }}
                </span>
                <div class="text-muted">{{ block.complex.name }}</div>
              </div>
            </div>
            <!--begin::Nav-->
            <div class="navi navi-bold navi-hover navi-active navi-link-rounded mt-15">
              <div class="navi-item mb-2">
                <div
                    v-for="type in locationTypes"
                    class="navi-link cursor-pointer py-4"
                    :class="{active: selectedType === type}"
                    @click="changeType(type)"
                >
                  <span class="navi-icon mr-2">
                    <span class="svg-icon">
                      <inline-svg src="/media/svg/icons/Media/Rec.svg"/>
                    </span>
                  </span>
                  <span class="navi-text font-size-lg">{{ $t(`label.${type}`) }}</span>
                </div>
              </div>
            </div>
            <!--end::Nav-->
          </template>
        </app-card>
        <!--end::Profile Card-->
      </div>
      <!--end::Aside-->
      <!--begin::Content-->
      <div class="flex-row-fluid ml-lg-8">
        <apartments-list v-if="selectedType === 'apartments'" :block-id="blockId"/>
        <floors-list v-if="selectedType === 'floors'" :block-id="blockId"/>
        <receptions-list v-if="selectedType === 'receptions'" :block-id="blockId"/>
        <offices-list v-if="selectedType === 'offices'" :block-id="blockId"/>
        <elevators-list
          v-if="selectedType === 'elevators'"
          :block-id="blockId"
          @showCertificates="showCertificatesList"
          @showGroundings="showGroundingsList"
        />
        <elevator-certificates-list
          v-if="selectedType === 'elevator_certificates'"
          :block-id="blockId"
          :elevator-id="elevatorId"
          :filters="{elevator: elevatorId}"
        />
        <elevator-groundings-list
          v-if="selectedType === 'elevator_groundings'"
          :block-id="blockId"
          :elevator-id="elevatorId"
          :filters="{elevator: elevatorId}"
        />
        <water-zones-list v-if="selectedType === 'water_zones'" :block-id="blockId"/>
        <water-pressure-list v-if="selectedType === 'water_pressure'" :block-id="blockId"/>
        <water-inspection-list v-if="selectedType === 'water_inspection'" :block-id="blockId"/>

        <fire-zones-list v-if="selectedType === 'fire_zones'" :block-id="blockId"/>
        <fire-water-pressure-list v-if="selectedType === 'fire_water_pressure'" :block-id="blockId"/>

        <portal to="subheader-toolbar">
          <b-button class="mr-3" variant="transparent-white" @click="$router.push({name: 'complexesList'})">
            {{ $t('btn.back_to_complexes') }}
          </b-button>
          <b-button v-if="selectedType === 'apartments'" class="mr-3" variant="outline-primary" @click="syncApartments">
            {{ $t('btn.sync_apartments') }}
          </b-button>
          <b-button v-if="selectedType === 'floors'" class="mr-3" variant="outline-primary" @click="syncFloors">
            {{ $t('btn.sync_floors') }}
          </b-button>
          <router-link :to="{name: 'updateBlock', params: {id: blockId}}" class="btn btn-light-success">
            {{ $t('btn.update') }}
          </router-link>
        </portal>
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {ADD_BREADCRUMB, SET_BREADCRUMB} from '@/store/core/breadcrumbs.module';
import AppCard from '@/components/AppCard';
import AppTable from '@/components/lists/AppTable';
import ApartmentsList from './components/ApartmentsList';
import FloorsList from './components/FloorsList.vue';
import ElevatorsList from './components/ElevatorsList';
import ElevatorCertificatesList from './components/ElevatorCertificatesList';
import ElevatorGroundingsList from './components/ElevatorGroundingsList';
import ReceptionsList from './components/ReceptionsList';
import OfficesList from './components/OfficesList';
import WaterInspectionList from './components/WaterInspectionList';
import WaterPressureList from './components/WaterPressureList';
import WaterZonesList from './components/WaterZonesList';
import FireZonesList from './components/FireZonesList';
import FireWaterPressureList from './components/FireWaterPressureList';

export default {
  components: {
    AppCard,
    AppTable,
    ApartmentsList,
    FloorsList,
    ElevatorsList,
    ElevatorCertificatesList,
    ElevatorGroundingsList,
    ReceptionsList,
    OfficesList,
    WaterInspectionList,
    WaterPressureList,
    WaterZonesList,
    FireZonesList,
    FireWaterPressureList
  },
  data() {
    return {
      blockId: null,
      elevatorId: null,
      defaultHashType: 'apartments',
      selectedType: null,
      updateApartmentsDataFlag: false,
      updateFloorsDataFlag: false,
      locationTypes: ['apartments', 'floors', 'receptions', 'offices', 'elevators', 'water_zones', 'water_pressure', 'water_inspection', 'fire_zones', 'fire_water_pressure'],
    }
  },
  beforeMount() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: 'blocks'}]);
    this.blockId = this.$route.params.id;
    this.$store.dispatch('blocksStore/GET_BLOCK', this.blockId);

    this.selectTypeByHash();
  },
  computed: {
    ...mapGetters({
      block: 'blocksStore/BLOCK',
      subheaderMobileState: 'subheaderMobileToggle'
    })
  },
  watch: {
    block(data) {
      this.$store.dispatch(ADD_BREADCRUMB, [{title: this.$t('label.block') + ' ' + data.name}]);
    },
    updateApartmentsDataFlag(val) {
      if (val) {
        setTimeout(() => {
          this.updateApartmentsDataFlag = false;
        }, 200)
      }
    },
    updateFloorsDataFlag(val) {
      if (val) {
        setTimeout(() => {
          this.updateFloorsDataFlag = false;
        }, 200)
      }
    },
    selectedType: {
      handler(name) {
        if (!name) name = this.defaultHashType;
        if (`#${name}` !== this.$route.hash) {
          location.hash = `#${name}`;
        }
      }
    }
  },
  methods: {
    syncApartments() {
      this.$store.dispatch('apartmentsStore/SYNC').then(() => {
        this.updateApartmentsDataFlag = true;
      })
    },
    syncFloors() {
      this.$store.dispatch('floorsStore/SYNC').then(() => {
        this.updateFloorsDataFlag = true;
      })
    },
    showCertificatesList(elevatorId) {
      this.elevatorId = elevatorId;
      this.selectedType = 'elevator_certificates';
    },
    showGroundingsList(elevatorId) {
      this.elevatorId = elevatorId;
      this.selectedType = 'elevator_groundings';
    },
    selectTypeByHash() {
      let hash = this.$route.hash === '#elevator_certificates' || this.$route.hash === '#elevator_groundings' ? '#elevators' : this.$route.hash;
      this.selectedType = hash ? hash.substring(1) : this.defaultHashType;
    },
    handleFocusOut() {
      this.$store.commit('changeSubheaderMobileToggleState', false);
    },
    changeType(type) {
      this.handleFocusOut();
      this.selectedType = type;
    }
  }
}
</script>

<style lang="scss" scoped>
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>

<style>
@media (max-width: 425px) {
  #kt_wrapper {
    //flex-direction: column !important;

    #kt_profile_aside {
      //width: 100% !important;
      //position: inherit;
      //margin-bottom: 15px;
    }
  }
}
</style>