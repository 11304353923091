<template>
  <app-card>
    <template #title>{{ $t('label.elevators') }}</template>
    <template #toolbar>
      <router-link :to="{name: 'createElevator', query: {block_id: blockId}}" class="btn btn-light-warning">
        {{ $t('btn.create_elevator') }}
      </router-link>
    </template>
    <template #body>
      <app-table :request-url="`/api/${blockId}/elevators/table`" :fields="fields" :filters="{block: blockId}" :update-data="updateDataFlag">
        <template #cell(block)="data">{{ data.value.name }}</template>
        <template #cell(complex)="data">{{ data.value.name }}</template>
        <template #cell(model)="data">
          <router-link :to="`/elevators/${data.item.id}/update`" class="text-primary text-hover-success">
            {{ data.value }}
          </router-link>
        </template>
        <template #cell(certificates)="data">
          <a href="javascript:void(0);" @click="$emit('showCertificates', data.item.id)">{{ $t('btn.view') }}</a>
        </template>
        <template #cell(groundings)="data">
          <a href="javascript:void(0);" @click="$emit('showGroundings', data.item.id)">{{ $t('btn.view') }}</a>
        </template>
      </app-table>
    </template>
  </app-card>
</template>

<script>
import AppCard from '@/components/AppCard';
import AppTable from '@/components/lists/AppTable';

export default {
  components: {
    AppCard,
    AppTable
  },
  props: {
    blockId: {
      required: true
    },
    updateDataFlag: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fields: [
        {key: 'model', label: this.$t('label.model')},
        {key: 'number', label: this.$t('label.number')},
        {key: 'factory_number', label: this.$t('label.factory_number')},
        {key: 'serial_number', label: this.$t('label.serial_number')},
        {key: 'manufacture_date', label: this.$t('label.manufacture_date')},
        {key: 'start_date', label: this.$t('label.start_date')},
        {key: 'stops_number', label: this.$t('label.stops_number')},
        {key: 'load_capacity', label: this.$t('label.load_capacity')},
        {key: 'speed', label: this.$t('label.speed')},
        {key: 'state_registration', label: this.$t('label.state_registration')},
        {key: 'certificates', label: this.$t('label.certificates')},
        {key: 'groundings', label: this.$t('label.groundings')}
      ]
    }
  },
}
</script>