<template>
  <app-card>
    <template #title>{{ $t('label.water_inspection') }}</template>
    <template #body>
      <app-table :request-url="`/api/${blockId}/waterInspection/table`" :fields="fields" :filters="{block: blockId}" :update-data="updateDataFlag">
        <template #cell(id)="data">
          <router-link :to="{name: 'updateWaterInspection', params: {id: data.item.id}}" class="text-primary text-hover-success">
            {{ data.value }}
          </router-link>
        </template>
        <template #cell(created_by)="data">{{ data.value.full_name }}</template>
        <template #cell(basic)="data"></template>
        <template #cell(reserve)="data"></template>
        <template #cell(fire)="data"></template>
      </app-table>
    </template>
  </app-card>
</template>

<script>
import AppCard from '@/components/AppCard';
import AppTable from '@/components/lists/AppTable';

export default {
  components: {
    AppCard,
    AppTable
  },
  props: {
    blockId: {
      required: true
    },
    updateDataFlag: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fields: [
        {key: 'id', label: 'ID'},
        {key: 'date', label: this.$t('label.date')},
        {key: 'basic', label: this.$t('label.basic')},
        {key: 'reserve', label: this.$t('label.reserve')},
        {key: 'fire', label: this.$t('label.fire')},
        {key: 'created_by', label: this.$t('label.created_by')},
        {key: 'created_at', label: this.$t('label.created_at')},
        {key: 'updated_at', label: this.$t('label.updated_at')}
      ]
    }
  },
}
</script>