<template>
  <app-card>
    <template #title>{{ $t('label.receptions') }}</template>
    <template #toolbar>
      <router-link :to="{name: 'createReception', query: {block_id: blockId}}" class="btn btn-light-warning">
        {{ $t('btn.create_reception') }}
      </router-link>
    </template>
    <template #body>
      <app-table :request-url="`/api/${blockId}/receptions/table`" :fields="fields" :filters="{block: blockId}" :update-data="updateDataFlag">
        <template #cell(block)="data">{{ data.value.name }}</template>
        <template #cell(complex)="data">{{ data.value.name }}</template>
        <template #cell(name)="data">
          <router-link :to="`/receptions/${data.item.id}/update`" class="text-primary text-hover-success">
            {{ data.value }}
          </router-link>
        </template>
      </app-table>
    </template>
  </app-card>
</template>

<script>
import AppCard from '@/components/AppCard';
import AppTable from '@/components/lists/AppTable';

export default {
  components: {
    AppCard,
    AppTable
  },
  props: {
    blockId: {
      required: true
    },
    updateDataFlag: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fields: [
        {key: 'name', label: this.$t('label.name')},
        {key: 'complex', label: this.$t('label.complex')},
        {key: 'block', label: this.$t('label.block')}
      ]
    }
  },
}
</script>