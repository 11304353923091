<template>
  <app-card>
    <template #title>{{ $t('label.apartments') }}</template>
    <template #body>
      <app-table :request-url="`/api/${blockId}/apartments/table`" :fields="fields">
        <template #cell(block)="data">{{ data.value.name }}</template>
        <template #cell(complex)="data">{{ data.value.name }}</template>
      </app-table>
    </template>
  </app-card>
</template>

<script>
import AppCard from '@/components/AppCard';
import AppTable from '@/components/lists/AppTable';

export default {
  components: {
    AppCard,
    AppTable
  },
  props: {
    blockId: {
      required: true
    }
  },
  data() {
    return {
      fields: [
        {key: 'name', label: this.$t('label.name')},
        {key: 'complex', label: this.$t('label.complex')},
        {key: 'block', label: this.$t('label.block')},
        {key: 'floor', label: this.$t('label.floor'), class: 'text-right'},
        {key: 'contract_status', label: this.$t('label.contract_status'), class: 'text-right'},
      ]
    }
  },
}
</script>