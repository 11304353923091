<template>
  <app-card>
    <template #title>{{ expiringGroundings ? $t('label.expiring_groundings') : $t('label.groundings') }}</template>
    <template #toolbar>
      <router-link
        v-if="!expiringGroundings"
        :to="{name: 'createElevatorGrounding', query: {block_id: blockId, elevator_id: elevatorId}}"
        class="btn btn-light-warning"
      >
        {{ $t('btn.create_grounding') }}
      </router-link>
    </template>
    <template #body>
      <app-table
        :request-url="`/api/${elevatorId}/elevators/groundings/table`"
        :fields="fields"
        :filters="filters"
        :update-data="updateDataFlag"
      >
        <template #cell(name)="data">
          <router-link
            :to="{name: 'updateElevatorGrounding', params: {id: data.item.id}}"
            class="text-primary text-hover-success"
          >
            {{ data.value }}
          </router-link>
        </template>
        <template #cell(block)="data">{{ data.item.block ? data.item.block.full_name : '' }}</template>
        <template #cell(inspection_date)="data">{{ data.value }}</template>
        <template #cell(expired_date)="data">{{ data.value }}</template>
        <template #cell(document)="data">
          <a v-if="data.value" :href="data.value" target="_blank">{{ $t('label.view') }}</a>
          <span v-else>-</span>
        </template>
      </app-table>
    </template>
  </app-card>
</template>

<script>
import AppCard from '@/components/AppCard';
import AppTable from '@/components/lists/AppTable';

export default {
  components: {
    AppCard,
    AppTable
  },
  props: {
    blockId: {
      required: true
    },
    elevatorId: {
      required: true
    },
    filters: {
      type: Object,
      default: () => {}
    },
    updateDataFlag: {
      type: Boolean,
      default: false
    },
    expiringGroundings: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fields: [
        {key: 'name', label: this.$t('label.name')},
        {key: 'block', label: this.$t('label.block')},
        {key: 'inspection_date', label: this.$t('label.inspection_date')},
        {key: 'expired_date', label: this.$t('label.expired_at')},
        {key: 'document', label: this.$t('label.document')}
      ]
    }
  },
}
</script>